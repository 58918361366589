import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useParams
} from "react-router-dom";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Button,
  Card,
  Form,
} from "react-bootstrap";
import Rating from "../components/Rating";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import { addToCart } from "../actions/cartActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import moment from "moment";

function ProductScreen() {
  const { id } = useParams();
  const [showAddToCartSuccess, setShowAddToCartSuccess] = useState(false);

  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productCreateReview = useSelector((state) => state.productCreateReview);
  const {
    loading: loadingProductReview,
    error: errorProductReview,
    success: successProductReview,
  } = productCreateReview;

  useEffect(() => {
    if(successProductReview){
      setRating(0)
      setComment('')
      dispatch({type: PRODUCT_CREATE_REVIEW_RESET})
    }

    dispatch(listProductDetails(id));

    return () => {
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    };

  }, [dispatch, id, successProductReview]);

  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const addToCartHandler = () => {
    dispatch(addToCart(id, qty));
    setShowAddToCartSuccess(true);
    setTimeout(() => setShowAddToCartSuccess(false), 3000);
  };

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(createProductReview(id, {rating, comment}))
  }

  return (
    <div>
      <Link to="/" className="btn btn-light my-3">
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
        <Row>
          <Col md={6}>
            <Image src={product.image} alt={product.name} fluid />
          </Col>
          <Col md={3}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3>{product.name}</h3>
              </ListGroup.Item>

              <ListGroup.Item>
                <Rating
                  value={product.rating}
                  text={`${product.numReviews} reviews`}
                  color={"#f8e825"}
                />
              </ListGroup.Item>

              <ListGroup.Item>Price: ${product.price}</ListGroup.Item>

              <ListGroup.Item>
                Description: {product.description}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={3}>
            <Card>
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Col>Price:</Col>
                    <Col>
                      <strong>${product.price}</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Col>Status:</Col>
                    <Col>
                      {product.countInStock > 0 ? "In Stock" : "Out of Stock"}
                    </Col>
                  </Row>
                </ListGroup.Item>

                {product.countInStock > 0 && (
                  <ListGroup.Item>
                    <Row>
                      <Col>Qty</Col>
                      <Col xs="auto" className="my-1">
                        <Form.Control
                          as="select"
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        >
                          {[...Array(product.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={Number(x) + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}

                <ListGroup.Item>
                  <Button
                    onClick={addToCartHandler}
                    className="btn-block w-100"
                    disabled={product.countInStock === 0}
                    type="button"
                    style={{ marginBottom: '0.5rem' }}
                  >
                    ADD TO CART
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Card>
            {showAddToCartSuccess ? <Message variant='success' className='mt-3' style={{ textAlign: 'center' }}>Items added to cart!</Message> : null}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ListGroup variant='flush'>
              <ListGroup.Item><h4 className='mt-5'>Reviews</h4>
                {product.reviews.length === 0 && <Message variant='info'>No reviews yet, be our first !</Message>}
              </ListGroup.Item>
              
              {product.reviews.map((review) => (
                <ListGroup.Item key={review._id}>
                  <strong>{review.name}</strong>
                  <Rating value={review.rating} color='#f8ea25'/>
                  <p>{moment(review.createdAt).format("MMMM Do, YYYY")}</p>
                  <p>{review.comment}</p>
                </ListGroup.Item>
              ))}

              <ListGroup.Item>
                <h4 className="mt-5">Add a review</h4>
                
                {loadingProductReview && <Loader/>}
                {successProductReview && <Message variant='success'>Review submitted !</Message>}
                {errorProductReview && <Message variant='danger'>{errorProductReview}</Message>}

                {userInfo ? (
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId="rating">
                      <Form.Label>Rating</Form.Label>
                      <Form.Control
                      as='select'
                      value={rating}
                      onChange={(e) => setRating(e.target.value)}
                      >
                        <option value=''>Select ...</option>
                        <option value='1'>1 - Poor</option>
                        <option value='2'>2 - Fair</option>
                        <option value='3'>3 - Good</option>
                        <option value='4'>4 - Very good</option>
                        <option value='5'>5 - Excellent</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='comment'>
                      <Form.Label className="mt-2">Review</Form.Label>
                      <Form.Control
                      as='textarea'
                      row='5'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      >
                      </Form.Control>
                    </Form.Group>

                    <Button
                      disabled={loadingProductReview}
                      type='submit'
                      variant="primary"
                      className="mt-3"
                    >
                      Submit
                    </Button>

                  </Form>
                ) : (
                  <Message variant='info'>Please <Link to='/login'>login</Link> to write a review</Message>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        </div>
      )}
    </div>
  );
}

export default ProductScreen;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTotal, setOption } from '../../actions/orderActions'

import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

function ToggleChoice({ firstOption, secondOption }) {
  const options = [firstOption, secondOption];
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const selectedOption = useSelector((state) => state.selectedOption);
  const dispatch = useDispatch();

  const items = cartItems.map((item) => ({
    id: item.product,
    qty: item.qty,
  }));

  useEffect(() => {
    dispatch(getTotal(items, selectedOption))
  })

  const handleChange = (value) => {
    dispatch(setOption(value, items));
  };

  return (
    <ToggleButtonGroup 
      type="radio" 
      className='w-100 mb-3'
      name="options" 
      value={selectedOption} 
      onChange={handleChange}
    >
      {options.map((option, index) => (
        <ToggleButton style={{backgroundColor: selectedOption === option ? 'black': '#ffc600'}} key={index} id={`tbg-radio-${index}`} value={option}>
          {option}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default ToggleChoice;
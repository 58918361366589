export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST"
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS"
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL"

export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET"

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST"
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS"
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL"

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST"
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS"
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL"
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET"

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST"
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS"
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL"

export const ORDER_SHIPPED_REQUEST = "ORDER_SHIPPED_REQUEST"
export const ORDER_SHIPPED_SUCCESS = "ORDER_SHIPPED_SUCCESS"
export const ORDER_SHIPPED_FAIL = "ORDER_SHIPPED_FAIL"
export const ORDER_SHIPPED_RESET = "ORDER_SHIPPED_RESET"

export const ORDER_DELIVERED_REQUEST = "ORDER_DELIVERED_REQUEST"
export const ORDER_DELIVERED_SUCCESS = "ORDER_DELIVERED_SUCCESS"
export const ORDER_DELIVERED_FAIL = "ORDER_DELIVERED_FAIL"
export const ORDER_DELIVERED_RESET = "ORDER_DELIVERED_RESET"

export const ORDER_GET_TOTAL_REQUEST = "ORDER_GET_TOTAL_REQUEST"
export const ORDER_GET_TOTAL_FAIL = "ORDER_GET_TOTAL_FAIL"
export const ORDER_GET_TOTAL_SUCCESS = "ORDER_GET_TOTAL_SUCCESS"

export const ORDER_GET_STRIPE_INFO_REQUEST = "ORDER_GET_STRIPE_INFO_REQUEST"
export const ORDER_GET_STRIPE_INFO_FAIL = "ORDER_GET_STRIPE_INFO_FAIL"
export const ORDER_GET_STRIPE_INFO_SUCCESS = "ORDER_GET_STRIPE_INFO_SUCCESS"

export const CHECKOUT_SESSION_REQUEST = "CHECKOUT_SESSION_REQUEST"
export const CHECKOUT_SESSION_SUCCESS = "CHECKOUT_SESSION_SUCCESS"
export const CHECKOUT_SESSION_FAIL = "CHECKOUT_SESSION_FAIL"

import axios from 'axios'
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_SAVE_SHIPPING_ADDRESS, CART_SAVE_PAYMENT_METHOD, CART_EDIT_ITEM_QTY } from '../constants/cartConstants'
import { API_URL } from '../constants/urls'

export const addToCart = (id, qty) => async(dispatch, getState) => {
    const { data } = await axios.get(API_URL + `/api/products/${id}`);
    const cartItems = getState().cart.cartItems || [];
    const currentCartQty = cartItems.find(cartItem => data._id === cartItem.product)?.qty || 0;

    dispatch({
        type:CART_ADD_ITEM,
        payload:{
            product:data._id,
            name:data.name,
            image:data.image,
            price:data.price,
            countInStock:data.countInStock,
            qty: Number(currentCartQty) + Number(qty)
        }
    })

    const updatedCartItems = getState().cart.cartItems;
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
} 

export const editItemQuantity = (id, qty) => async(dispatch, getState) => {
    const { data } = await axios.get(API_URL + `/api/products/${id}`);
    
    dispatch({
        type:CART_EDIT_ITEM_QTY,
        payload:{
            product:data._id,
            name:data.name,
            image:data.image,
            price:data.price,
            countInStock:data.countInStock,
            qty: Number(qty)
        }
    })

    const updatedCartItems = getState().cart.cartItems;
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
} 

export const removeFromCart = (id) => (dispatch, getState) => {

    dispatch({
        type: CART_REMOVE_ITEM,
        payload: id,
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))

}

export const saveShippingAddress = (data) => (dispatch) => {

    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,
    })

    localStorage.setItem('shippingAddress', JSON.stringify(data))

}

export const savePaymentMethod = (data) => (dispatch) => {

    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data,
    })

    localStorage.setItem('paymentMethod', JSON.stringify(data))

}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import CheckoutForm from "../../../components/CheckoutForm";
import {
  getStripeInfo,
  createPaymentIntent,
} from "../../../actions/orderActions";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


function Payment(amount) {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [stripeFetched, setStripeFetched] = useState(false);
    const { info } = useSelector((state) => state.orderStripeInfo);
    const { stripe_public } = info || {};
  
    const dispatch = useDispatch();
  
    // Fetch public key
    useEffect(() => {
      const fetchData = async () => {
        const myKey = await dispatch(getStripeInfo());
        setStripeFetched(true);
      };
  
      fetchData();
  
      // Load fn from stripe
      if (stripe_public && stripeFetched && !stripePromise) {
        const loadStripePromise = async () => {
          const stripe = await loadStripe(stripe_public);
          setStripePromise(stripe);
        };
  
        loadStripePromise();
      }
  
      const getClientSecret = async () => {
        if (stripePromise) {
          const paymentIntentData = await dispatch(createPaymentIntent(amount));
          setClientSecret(paymentIntentData.client_secret);
        }
      };
      getClientSecret();
    }, [dispatch, amount, stripePromise, stripe_public, stripeFetched]);
  
  
    return (
      <>
        <h2>Payment</h2>
        {!clientSecret && <Loader />}
        {stripePromise && clientSecret && (
            
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm clientSecret={clientSecret}/>
          </Elements>
        )}
      </>
    );
  }

  export default Payment
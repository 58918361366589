import { Row, Col, ListGroup, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Message from "../../components/Message";
import CheckoutSteps from "../../components/CheckoutSteps";

import OrderSummary from './components/OrderSummary'
import ToggleChoice from '../../components/DeliveryPickup'


function PlaceOrderScreen() {
  const cart = useSelector((state) => state.cart);
  const selectedOption = useSelector((state) => state.selectedOption);

  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Billing / Shipping</h2>
              <p>
                <strong>Address:</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city},
                {"   "}
                {cart.shippingAddress.postalCode},{"   "}
                {"Canada"}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message variant="info">Your cart is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={2}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>

                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>

                        <Col md={4}>
                          {item.qty} x ${item.price} = $
                          {(item.qty * item.price).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <ToggleChoice
            firstOption="Shipping"
            secondOption="Pick-up"
          />
          {selectedOption === "Shipping" && 
            <p>*A shipping fee of 15$ is applied for orders under 100$, we only ship within the Montreal metropolitan area.</p>
          }
          {selectedOption === "Pick-up" && 
            <p>*Pick-up is in the Chateauguay area, the exact address will be emailed upon purchase confirmation.</p>
          }
          <OrderSummary cart={cart} orderOption={selectedOption} />
        </Col>
      </Row>
    </div>
  );
}

export default PlaceOrderScreen;
